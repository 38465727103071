
/* 
The three leading colorcodes
1 - Lightpink -> #FFD9CC
2 - Yellow -> #FFFF73
3 - Forest Green -> #99BF33
4 - Black -> #000000 (for text)
5 - Lightgray -> #808080 (for text)
6 - White ->     (for background)
*/

:root {
  --header-height: 5rem;

  --standard-margin-side: 3rem;
  --standard-margin-bottom: 10rem;

  --standard-box-shadow: 0.1rem 0.7rem 2rem rgba(0, 0, 0, 0.5);
  --standard-border-radius: 0.2rem;
  --alpha: 0.9;
  
  --title-font-size-main: 3rem;
  --title-font-size-sub: 1.8rem;
  --body-font-size: 1.2rem;
  --body-line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;

  font: Abel;
}

#root {
  /* padding-top: calc(2 * var(--header-height)); */
  padding-top: var(--header-height);
}
/* 
body {
  padding-top: calc(2 * var(--header-height));
} */


/* Scrollbar settings */
::-webkit-scrollbar {
  /* color: #FFFF73; */
  /* width: 20px; */
  background-color: white;
  /* background: #FFFF73; */
  /* display: none; */
}


/* Code to disable any scrollbars */
::-webkit-scrollbar-thumb {
  background: #808080;
  /* background: #99BF33; */
  /* border-style: solid; */
}


/* Background settings*/
.background-index {
  position: fixed;
  z-index: -1;

  left: -25%;
  top: -20%;

  width: 150%;

  opacity: 0.20;
}


/* 
The three leading colorcodes
1 - Lightpink -> #FFD9CC  rgb(255, 217, 204)
2 - Yellow -> #FFFF73  rgb(255, 255, 115)
3 - Forest Green -> #99BF33  rgb(153, 191, 51)
4 - Black -> #000000 (for text)
5 - Lightgray -> #808080 (for text)  rgb(128, 128, 128)
6 - White ->     (for background)
*/


/* Introduction section */
.introduction {

  margin-top: var(--header-height);
  margin-bottom: 10rem;
  margin-left: 5rem;
  margin-right: 5rem;
  

  text-align: center; 
}

.introduction-title {
  margin-bottom: 0.1rem;
  font-size: 6rem;
}

.introduction-line {    
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;

  width: 20rem;
  height: 0.15rem;

  background-color: #808080;

  border-radius: 5px;

  transition: all 0.5s;

  animation-name: shift-line-introduction;
  animation-duration: 1s;
}

@keyframes shift-line-introduction {
  from {width: 10rem;}
  to {width: 20rem;}
}

.introduction-subtitle {
  margin-bottom: 2rem;
  font-size: 2rem;

  font-weight: 550;

  color: #808080;
}

.introduction-body {
  font-size: 1.5rem;
  line-height: 2rem;
}


/* Flex image classes */
.flex-text-image {
  display: flex;
  flex-direction: row;
  justify-content: top; 

  margin-bottom: var(--standard-margin-bottom);
  margin-left: var(--standard-margin-side);
  margin-right: var(--standard-margin-side);

  height: 40rem;

  box-shadow: var(--standard-box-shadow);

  border-radius: var(--standard-border-radius);
  overflow: hidden;
}

.flex-image {
  width: 36%;
  height: 100%;

  /* images use the line height of their container and not the actual height, this can cause the container to be too large */ 
  line-height: 0; 
  background-color: rgba(153, 191, 51, var(--alpha));
}

.flex-image-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flex-text {
  width: 64%;
}

.flex-text-top,
.flex-text-bottom {
  box-sizing: border-box;

  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 3rem;
  padding-bottom: 2rem;

  height: 50%;
  width: 100%;

  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.flex-text-top {
  background-color: rgba(255, 217, 204, var(--alpha));
}

.flex-text-bottom {
  background-color: rgba(255, 255, 115, var(--alpha));
}


.flex-text-title {
  font-size: var(--title-font-size-sub);
  padding-bottom: 2rem;
}

.flex-text-text {
  font-size: var(--body-font-size);
}

/* Grid-text-image classes */
.grid-text-image {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  margin-bottom: var(--standard-margin-bottom);
  margin-left: var(--standard-margin-side);
  margin-right: var(--standard-margin-side);

  box-shadow: var(--standard-box-shadow);

  border-radius: var(--standard-border-radius);
  overflow: hidden;
}

.grid-text-pink,
.grid-text-yellow {
  box-sizing: border-box;

  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 3rem;
  padding-bottom: 2rem;

  height: 23rem;
}

.grid-text-pink {
  background-color: rgba(255, 217, 204, var(--alpha));
}

.grid-text-yellow {
  background-color: rgba(255, 255, 115, var(--alpha));
}

.grid-text-title {
  padding-bottom: 2rem;

  font-size: var(--title-font-size-sub);  
}

.grid-text-text {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.grid-image {
  height: 23rem;
  background-color: rgba(153, 191, 51, var(--alpha));    
}

.grid-image-image {
  width: 100%;  /* this basically says to scale the image exactly in its container */ 
  height: 100%;

  object-fit: cover;   /* iun order for object fit to work, the image needs a height and width */
}



/* Background settings*/
.background-index {
  position: fixed;
  z-index: -1;

  left: -25%;
  top: -20%;

  width: 150%;

  opacity: 0.20;
}
