/* 
The three leading colorcodes
1 - Lightpink -> #FFD9CC  rgb(255, 217, 204)
2 - Yellow -> #FFFF73  rgb(255, 255, 115)
3 - Forest Green -> #99BF33  rgb(153, 191, 51)
4 - Black -> #000000 (for text)
5 - Lightgray -> #808080 (for text)  rgb(128, 128, 128)
6 - White ->     (for background)
*/

.background-image-werkwijze {
  position: fixed;
  z-index: -1;
  width: 100%;
  opacity: 0.2;
  top: 0px;
}
