/* 
The three leading colorcodes
1 - Lightpink -> #FFD9CC  rgb(255, 217, 204)
2 - Yellow -> #FFFF73  rgb(255, 255, 115)
3 - Forest Green -> #99BF33  rgb(153, 191, 51)
4 - Black -> #000000 (for text)
5 - Lightgray -> #808080 (for text)  rgb(128, 128, 128)
6 - White ->     (for background)
*/

/* Background settings*/
.background-tarieven {
  position: fixed;
  z-index: -1;
  width: 100%;
  opacity: 0.2;

  top: -100px;
}

/* tarieven part */
.tarieven {
  position: relative;

  margin-top: var(--header-height);
  margin-left: var(--standard-margin-side);
  margin-right: var(--standard-margin-side);
  margin-bottom: var(--standard-margin-bottom);

  padding-top: 1rem;
  padding-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;

  background-color: rgba(255, 217, 204, var(--alpha));

  box-shadow: var(--standard-box-shadow);

  border-radius: var(--standard-border-radius);
  overflow: hidden;
}


.sidebar-left {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;

  width: 2.7rem;

  background-color: rgba(153, 191, 51, var(--alpha));
}


.bar-bottom {
  position: absolute;

  left: 2.7rem;
  right: 0;
  bottom: 0;

  height: 2.7rem;

  background-color: rgba(255, 255, 115, var(--alpha));
}

.tarieven-title {
  position: relative;

  margin-bottom: 2rem;

  font-size: var(--title-font-size-main);
  text-align: center;

  font-size: 6rem;
}

.tarieven-title-line {    
  position: absolute;
  
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;

  width: 15rem;
  height: 0.1rem;

  background-color: #808080;

  border-radius: 1rem;

  animation-name: shift-tarieven-title-line;
  animation-duration: 1s;
}


@keyframes shift-tarieven-title-line {
  from {width: 7.5rem;}
  to {width: 15rem;}
}

.tarieven-text {
  margin-bottom: 2rem;

  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.contact-link {
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
}

.contact-link:hover {
  text-decoration: underline;
}

.tarieven-text-2 {
  margin-bottom: 2rem;
  text-align: center;

  font-size: 30px;
  font-weight: bold;
}


/* Enumerating examples part */
.enumeration {
  position: relative;

  margin-left: var(--standard-margin-side);
  margin-right: var(--standard-margin-side);
  margin-bottom: var(--standard-margin-bottom);

  padding-top: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;

  background-color: rgba(255, 217, 204, var(--alpha));

  box-shadow: var(--standard-box-shadow);

  border-radius: var(--standard-border-radius);
  overflow: hidden;
}

.enumeration-title {
  position: relative;
  
  margin-bottom: 3rem;

  font-size: var(--title-font-size-main);
  text-align: center;
}

.enumeration-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 5rem;

  /* background-color: rgba(255, 217, 204, var(--alpha)); */
}

.enumeration-section-title {
  margin-bottom: 1rem;

  font-size: var(--title-font-size-sub);
}


.enumeration-section-text {
  width: 30%;

  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.enumeration-section-image {
  display: inline-block;

  width: 30%;
  height: 20rem;

  border-radius: var(--standard-border-radius);
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);

  overflow: hidden;
}

.enumeration-section-image-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}








