/* Footer class */
.footer {
  position: relative;

  left: 0;
  right: 0;
  bottom: 0;

  margin-bottom: 2rem;

  padding-top: 2rem;
  padding-bottom: 3rem;

  background-color: rgba(153, 191, 51, var(--alpha));

  box-shadow: 0.1rem 0.7rem 2rem rgba(0, 0, 0, 0.5);
}

.footer-title {
  margin-bottom: 2rem;

  font-size: var(--title-font-size-main);
  text-align: center;

  cursor: pointer;
}

.footer-information {
  margin-left: auto;
  margin-right: auto;

  width: 19rem;

  font-size: var(--body-font-size);
}

.footer-information-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
}

.icon-and-text {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.icon-adress,
.icon-phone {
  margin-right: 1rem;

  width: 1.5rem;
  height: 1.9rem;
}

.icon-email {
  margin-right: 0.7rem;

  width: 1.8rem;
  height: 1.3rem;
}

.footer-information-line {
  margin-left: auto;
  margin-right: auto;

  width: 17rem;
  height: 0.125rem;

  background-color: #808080;
  border-radius: 1rem;
}

.credit {
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 1rem;

  font-size: 1rem;
  text-align: center;
  color: #808080;
  opacity: 0.7;
}
