/* 
The three leading colorcodes
1 - Lightpink -> #FFD9CC
2 - Yellow -> #FFFF73
3 - Forest Green -> #99BF33
4 - Black -> #000000 (for text)
5 - Lightgray -> #808080 (for text)
6 - White ->     (for background)
*/

/* Class for the header as a whole */
.header {
  position: fixed;
  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: top;

  top: 0;
  left: 0;
  right: 0;

  height: var(--header-height);

  box-shadow: var(--standard-box-shadow);

  transition: all 0.5s;
}

.animate-header {
  top: calc(-1 * var(--header-height));
}

/* Classes for all the tabs */
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  flex: 5;
}

/* Note that these cannot be targeted at once as this messes up the loading order and the active button class disappears */
.logo-button,
.ontwerpen-button,
.tarieven-button,
.contact-button,
.werkwijze-button {
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: var(--title-font-size-sub);
  /* font-weight: 500; */

  border: none;
  background: none;

  cursor: pointer;

  text-decoration: none;
  color: black;

  box-sizing: border-box;

}

.logo-button {
  position: relative;

  color: white;
  background-color: #808080;

  overflow: hidden;
}

.logo-image {
  position: absolute;
  z-index: 1;

  left: 0;
  top: -80px;

  width: 100%;

  object-fit: contain;

  background-color: #808080;

  opacity: 0.20;
}

.logo-button .header-line {
  background-color: white;
}

.ontwerpen-button {
  background-color: #ffff73;
  /* background-color: white; */
  /* background-color: #FFD9CC; */
}

.tarieven-button {
  /* background-color: #99BF33; */
  background-color: #ffd9cc;
  /* background-color: white; */
  /* background-color: #FFFF73; */
}

.werkwijze-button {
  /* background-color: white; */
  /* background-color: #FFFF73; */
  background-color: #99bf33;
  /* background-color: #FFD9CC; */
}

.contact-button {
  /* background-color: #FFD9CC; */
  /* background-color: #99BF33; */
  background-color: white;
}

.header-line {
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;

  width: 5.5rem;
  height: 0.125rem;

  background-color: #808080;
  border-radius: 1rem;

  transition: all 0.5s;
}

.header a:hover > .header-line {
  width: 11rem;
}

.active-button {
  border-style: solid;
  border-color: black;
}

.active-line {
  width: 11rem;
}
