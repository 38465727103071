/* 
The three leading colorcodes
1 - Lightpink -> #FFD9CC
2 - Yellow -> #FFFF73
3 - Forest Green -> #99BF33
4 - Black -> #000000 (for text)
5 - Lightgray -> #808080 (for text)
6 - White ->     (for background)
*/

.background-image-design {
  position: fixed;
  z-index: -1;
  width: 100%;
  opacity: 0.2;
 
  top: -70px;

}

/* The examples menu */
.examples {
  position: relative;

  margin-bottom: var(--standard-margin-bottom);

  padding-left: 2%;
  padding-right: 2%;

  background-color: rgba(153, 191, 51, 0.9);
  background-color: rgba(255, 217, 204, 0.9);

  box-shadow: var(--standard-box-shadow);
}

/* All the code needed to get two arrows */
.center-left,
.center-right {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  bottom: 0;
}

.center-left {
  left: 1rem;
}

.center-right {
  right: 1rem;
}

.arrow-left, 
.arrow-right {
  position: absolute;
  z-index: 10;

  border-top: 1.5rem solid transparent;
  border-bottom: 1.5rem solid transparent;

  cursor: pointer;
}

.arrow-left {    
  border-right: 1.5rem solid rgb(153, 191, 51);
}

.arrow-right {
  border-left: 1.5rem solid rgb(153, 191, 51);
}


/* .dots {
  
} */

/* The actual menu with examples */
.examples-menu {
  display: flex;
  flex-direction: row;
  justify-content: top; /* we need to use start as the with the other justify-content arguments we get a cutoff in the beginning */
  align-items: center;

  height: 28rem;
  flex-wrap: nowrap;  /* I read that its necesary for scrolling but it does not seem to do anything, it seem like we only need overflow settings */

  /* This argument has a lot of options and is needed to be able to scroll, when set to hidden the overflow is still there but without a scrollbar, the
  arguments scroll and auto do the same but with a scrollbar. Other options do not seem necesary at the moment */
  overflow-x: hidden;
}

.design-example {
  position: relative;

  /* Setting the flex value like this makes sure that the elements are not squeezed in but override the screen */
  flex: 0 0 auto;

  margin-right: 1%;
  margin-left: 1%;

  width: 23%;
  height: 90%;

  border-radius: var(--standard-border-radius);
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.3);

  overflow: hidden;

  cursor: pointer;
}

.design-example-image {
  object-fit: cover;  /* very practical, makes sure that the image keeps its measurements while covering its space */
  height: 100%;
  width: 100%;
}

.design-example-text {
  position: absolute;

  left: 0;
  right: 0;
  bottom: 0;
  top: 87.5%;

  background-color: #808080;
  opacity: 0.9;

  color: white;

  /* transition: top 0.5s; */
  transition: all 0.5s;

}

.design-title {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  font-size: var(--title-font-size-sub);
  text-align: center;
}

.design-text {
  margin-bottom: 0.7rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.design-example:hover > .design-example-text {
  top: 55%;
}


