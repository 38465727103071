/* 
The three leading colorcodes
1 - Lightpink -> #FFD9CC  rgb(255, 217, 204)
2 - Yellow -> #FFFF73  rgb(255, 255, 115)
3 - Forest Green -> #99BF33  rgb(153, 191, 51)
4 - Black -> #000000 (for text)
5 - Lightgray -> #808080 (for text)  rgb(128, 128, 128)
6 - White ->     (for background)
*/

/* Background settings*/
.background-contact {
  position: fixed;
  z-index: -1;

  top: -100px;

  width: 100%;

  opacity: 0.2;
}


/* The contact information box and styles */
.contact {
  position: relative;

  margin-top: var(--header-height);
  margin-left: var(--standard-margin-side);
  margin-right: var(--standard-margin-side);
  margin-bottom: var(--standard-margin-bottom);

  /* padding-bottom: 5rem; */

  /* background-color: rgba(255, 217, 204, var(--alpha)); */
  /* background-color: rgba(153, 191, 51, var(--alpha)); */
  /* background-color: rgb(153, 191, 51, var(--alpha)); */

  /* border-radius: var(--standard-boorder-radius);
  box-shadow: var(--standard-box-shadow); */
}

.contact-title {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 5rem;

  font-size: var(--title-font-size-main);

  height: 7rem;
  font-size: 6rem;
}

.contact-information-line {
  position: absolute;

  left: 0;
  right: 0;
  bottom: -0.07rem;

  margin-left: auto;
  margin-right: auto;
  
  width: 20rem;
  height: 0.125rem;

  background-color: #808080;
  border-radius: 1rem;

  animation-name: shift-line-contact;
  animation-duration: 1s;
}

@keyframes shift-line-contact {
  from {width: 10rem;}
  to {width: 20rem;}
}

.contact-text-data {
  display: flex;
  flex-direction: row;
  justify-content: top;
  
  margin-left: 5%;
  margin-right: 5%;

  box-shadow: var(--standard-box-shadow);
  border-radius: var(--standard-border-radius);

  overflow: hidden;
}

.contact-text, 
.contact-data {
  /* This argument prevents the box becoming bigger when we add padding */
  box-sizing: border-box;

  padding: 7% 3% 7% 3%;

  width: 50%;
}

.contact-text {

  font-size: var(--body-font-size);
  line-height: var(--body-line-height);

  background-color: rgba(255, 255, 115, var(--alpha));
  background-color: rgba(255, 217, 204, var(--alpha));
}

.contact-data {

  background-color: rgb(153, 191, 51, var(--alpha));
  /* background-color: rgba(255, 217, 204, 0.9); */
  background-color: rgba(255, 255, 115, var(--alpha));
}

